<template>

  <div class="service__details">
    <b-row
      class="content-header"
    >

      <!-- Content Left -->
      <b-col
        class="content-header-left mb-2"
        cols="12"
        md="12"
      >
        <b-row class="breadcrumbs-top">
          <b-col cols="12">
            <h1 class="custom-header-title">
              View All Related Nominees
            </h1>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <!-- Table Container Card -->

    <b-card
      ref="taskListBlock"
      class="mb-3 p-2 without__bg must__bg extra__bg"
      no-body
    >
      <b-row
        class="content-header"
      >

        <!-- Content Left -->
        <b-col
          class="content-header-left booking_content_wrapper mb-2"
          cols="12"
          md="12"
        >
          <b-dropdown
            v-if="isMobile()"
            :text="currentTabTitle"
            variant="outline-primary"
            class="mobile-dropdown__arrow-adjust mb-50"
          >
            <b-dropdown-item
              v-if="!['Urn', 'Pedestal', 'Little Buddha'].includes(fileDetail.nvsFileType)"
              @click="changeTab(0, 'Intended User')"
            >
              Intended User
            </b-dropdown-item>
            <b-dropdown-item
              @click="changeTab(1, 'Next of Kin')"
            >
              Next of Kin
            </b-dropdown-item>
            <b-dropdown-item
              @click="changeTab(2, 'Addressee')"
            >
              Addressee
            </b-dropdown-item>
          </b-dropdown>
          <b-tabs
            v-model="activeTab"
            pills
            class="booking__content no-pr purchaser-tabs"
            :nav-class="[isMobile() ? 'd-none' : '']"
          >
            <!-- Tab: Information -->
            <b-tab v-if="!['Urn', 'Pedestal', 'Little Buddha'].includes(fileDetail.nvsFileType)">
              <template #title>
                <span class="d-none d-sm-inline">Intended User <span class="rounded-number">{{ fileDetail.intendedUsers ? fileDetail.intendedUsers.length : 0 }}</span></span>
              </template>
              <b-row
                v-if="fileDetail.intendedUsers && fileDetail.intendedUsers.length"
                class="content-header match-height service_request_task"
              >
                <!-- Content Left -->
                <b-col
                  v-for="(iu, key) in fileDetail.intendedUsers"
                  :key="'iu' + key"
                  class="content-header-left mb-2 top-info-right"
                  cols="12"
                  md="4"
                  style="position: relative;"
                >
                  <b-card
                    class="card-transaction iu-card"
                    no-body
                  >
                    <b-card-header class="no-bottom-border">
                      <b-card-title>Intended User #{{ key + 1 }}</b-card-title>
                      <b-button
                        variant="none"
                        class="btn-icon rounded-circle ml-auto mr-1 flat-primary-bg"
                      >
                        <feather-icon icon="UserIcon" />
                      </b-button>
                      <b-dropdown
                        variant="link"
                        no-caret
                        class="chart-dropdown"
                        toggle-class="p-0"
                        right
                      >
                        <template #button-content>
                          <feather-icon
                            icon="MoreVerticalIcon"
                            size="18"
                            class="text-body cursor-pointer"
                          />
                        </template>
                        <b-dropdown-item
                          v-if="canViewThisAction('nominee-edit', 'File')"
                          @click="intendedUserEdit(iu, key)"
                        >
                          Edit
                        </b-dropdown-item>
                        <b-dropdown-item
                          v-if="canViewThisAction('nominee-delete', 'File')"
                          @click="intendedUserRemove(iu, key)"
                        >
                          Remove
                        </b-dropdown-item>
                      </b-dropdown>
                    </b-card-header>
                    <b-card-body>
                      <span class="text-primary weight-600">
                        <span class="text-uppercase">{{ iu.name }}</span>
                        <b-badge
                          v-if="iu.isPurchaser"
                          variant="light-info"
                          class="ml-50"
                        >
                          {{ iu.purchaserNo }}
                        </b-badge>
                      </span>
                      <br>
                      <span class="text-uppercase">{{ iu.encryptedNric }}</span>
                      <br>
                      <span class="text-uppercase">{{ iu.relation || '-' }}</span>
                      <br>
                      <span>
                        <feather-icon
                          icon="TabletIcon"
                          size="22"
                          class="mr-25 iu-icon"
                        />
                        {{ iu.contact || '-' }}
                      </span>
                      <br>
                      <span>
                        <b-img
                          height="22"
                          :src="require('@/assets/images/admin/birthday.png')"
                          alt="Left image"
                          class="mr-25"
                        />
                        <!-- {{ (iu.customer && iu.customer.dob) ? dateFormat(iu.customer.dob) : '-' }} -->
                        {{ iu.dob ? dateFormat(iu.dob) : '-' }}
                      </span>
                      <br>
                      <span>
                        <feather-icon
                          icon="MapPinIcon"
                          size="22"
                          class="mr-25 iu-icon"
                        />
                        <span>
                          {{ getResidentialAddress(iu) }}
                        </span>
                      </span>
                    </b-card-body>
                  </b-card>
                </b-col>
              </b-row>
              <b-card v-else>
                <div
                  class="empty_block"
                >
                  <h4>No Intended User Yet</h4>
                  <b-button
                    v-if="canViewThisAction('nominee-add', 'File')"
                    type="button"
                    variant="flat-primary"
                    @click="openCreateModal('Intended User')"
                  >
                    <span class="align-middle">Add Intended User</span>
                  </b-button>
                </div>
              </b-card>
            </b-tab>
            <b-tab class="">
              <template #title>
                <span class="d-none d-sm-inline">Next of Kin <span class="rounded-number">{{ fileDetail.nextOfKins ? fileDetail.nextOfKins.length : 0 }}</span></span>
              </template>
              <b-row
                v-if="fileDetail.nextOfKins && fileDetail.nextOfKins.length"
                class="content-header match-height service_request_task"
              >
                <!-- Content Left -->
                <b-col
                  v-for="(iu, key) in fileDetail.nextOfKins"
                  :key="'iu' + key"
                  class="content-header-left mb-2 top-info-right"
                  cols="12"
                  md="4"
                  style="position: relative;"
                >
                  <b-card
                    class="card-transaction iu-card"
                    no-body
                  >
                    <b-card-header class="no-bottom-border">
                      <b-card-title>Next of Kin #{{ key + 1 }}</b-card-title>
                      <b-button
                        variant="none"
                        class="btn-icon rounded-circle ml-auto mr-1 flat-primary-bg"
                      >
                        <feather-icon icon="UserIcon" />
                      </b-button>
                      <b-dropdown
                        variant="link"
                        no-caret
                        class="chart-dropdown"
                        toggle-class="p-0"
                        right
                      >
                        <template #button-content>
                          <feather-icon
                            icon="MoreVerticalIcon"
                            size="18"
                            class="text-body cursor-pointer"
                          />
                        </template>
                        <b-dropdown-item
                          v-if="canViewThisAction('nominee-edit', 'File')"
                          @click="intendedUserEdit(iu, key)"
                        >
                          Edit
                        </b-dropdown-item>
                        <b-dropdown-item
                          v-if="canViewThisAction('nominee-delete', 'File')"
                          @click="intendedUserRemove(iu, key)"
                        >
                          Remove
                        </b-dropdown-item>
                      </b-dropdown>
                    </b-card-header>
                    <b-card-body>
                      <span class="text-primary weight-600">
                        <span class="text-uppercase">{{ iu.name }}</span>
                        <b-badge
                          v-if="iu.isPurchaser"
                          variant="light-info"
                          class="ml-50"
                        >
                          {{ iu.purchaserNo }}
                        </b-badge>
                      </span>
                      <br>
                      <span class="text-uppercase">{{ iu.encryptedNric }}</span>
                      <br>
                      <span class="text-uppercase">{{ iu.relation || '-' }}</span>
                      <br>
                      <span>
                        <feather-icon
                          icon="TabletIcon"
                          size="22"
                          class="mr-25 iu-icon"
                        />
                        {{ iu.contact || '-' }}
                      </span>
                      <br>
                      <span>
                        <b-img
                          height="22"
                          :src="require('@/assets/images/admin/birthday.png')"
                          alt="Left image"
                          class="mr-25"
                        />
                        <!-- {{ (iu.customer && iu.customer.dob) ? dateFormat(iu.customer.dob) : '-' }} -->
                        {{ iu.dob ? dateFormat(iu.dob) : '-' }}
                      </span>
                      <br>
                      <span>
                        <feather-icon
                          icon="MapPinIcon"
                          size="22"
                          class="mr-25 iu-icon"
                        />
                        <span>
                          {{ getResidentialAddress(iu) }}
                        </span>
                      </span>
                    </b-card-body>
                  </b-card>
                </b-col>
              </b-row>
              <b-card v-else>
                <div
                  class="empty_block"
                >
                  <h4>No Next Of Kin Yet</h4>
                  <b-button
                    v-if="canViewThisAction('nominee-add', 'File')"
                    type="button"
                    variant="flat-primary"
                    @click="openCreateModal('Next Of Kin')"
                  >
                    <span class="align-middle">Add Next Of Kin</span>
                  </b-button>
                </div>
              </b-card>
            </b-tab>
            <b-tab class="">
              <template #title>
                <span class="d-none d-sm-inline">Addressee <span class="rounded-number">{{ fileDetail.addressees ? fileDetail.addressees.length : 0 }}</span></span>
              </template>
              <b-row
                v-if="fileDetail && fileDetail.addressees && fileDetail.addressees.length"
                class="content-header match-height service_request_task"
              >
                <!-- Content Left -->
                <b-col
                  v-for="(iu, key) in fileDetail.addressees"
                  :key="'iu' + key"
                  class="content-header-left mb-2 top-info-right"
                  cols="12"
                  md="4"
                  style="position: relative;"
                >
                  <b-card
                    class="card-transaction iu-card"
                    no-body
                  >
                    <b-card-header class="no-bottom-border">
                      <b-card-title>Addressee #{{ key + 1 }}</b-card-title>
                      <b-button
                        variant="none"
                        class="btn-icon rounded-circle ml-auto mr-1 flat-primary-bg"
                      >
                        <feather-icon icon="UserIcon" />
                      </b-button>
                      <b-dropdown
                        variant="link"
                        no-caret
                        class="chart-dropdown"
                        toggle-class="p-0"
                        right
                      >
                        <template #button-content>
                          <feather-icon
                            icon="MoreVerticalIcon"
                            size="18"
                            class="text-body cursor-pointer"
                          />
                        </template>
                        <b-dropdown-item
                          v-if="canViewThisAction('nominee-edit', 'File')"
                          @click="intendedUserEdit(iu, key)"
                        >
                          Edit
                        </b-dropdown-item>
                        <b-dropdown-item
                          v-if="canViewThisAction('nominee-delete', 'File')"
                          @click="intendedUserRemove(iu, key)"
                        >
                          Remove
                        </b-dropdown-item>
                      </b-dropdown>
                    </b-card-header>
                    <b-card-body>
                      <span class="text-primary weight-600">
                        <span class="text-uppercase">{{ iu.name }}</span>
                        <b-badge
                          v-if="iu.isPurchaser"
                          variant="light-info"
                          class="ml-50"
                        >
                          {{ iu.purchaserNo }}
                        </b-badge>
                      </span>
                      <br>
                      <span class="text-uppercase">{{ iu.encryptedNric }}</span>
                      <br>
                      <span class="text-uppercase">{{ iu.relation || '-' }}</span>
                      <br>
                      <span>
                        <feather-icon
                          icon="TabletIcon"
                          size="22"
                          class="mr-25 iu-icon"
                        />
                        {{ iu.contact || '-' }}
                      </span>
                      <br>
                      <span>
                        <b-img
                          height="22"
                          :src="require('@/assets/images/admin/birthday.png')"
                          alt="Left image"
                          class="mr-25"
                        />
                        <!-- {{ (iu.customer && iu.customer.dob) ? dateFormat(iu.customer.dob) : '-' }} -->
                        {{ iu.dob ? dateFormat(iu.dob) : '-' }}
                      </span>
                      <br>
                      <span>
                        <feather-icon
                          icon="MapPinIcon"
                          size="22"
                          class="mr-25 iu-icon"
                        />
                        <span>
                          {{ getResidentialAddress(iu) }}
                        </span>
                      </span>
                    </b-card-body>
                  </b-card>
                </b-col>
              </b-row>
              <b-card v-else>
                <div
                  class="empty_block"
                >
                  <h4>No Addressee Yet</h4>
                  <b-button
                    v-if="canViewThisAction('nominee-add', 'File')"
                    type="button"
                    variant="flat-primary"
                    @click="openCreateModal('Addressee')"
                  >
                    <span class="align-middle">Add Addressee</span>
                  </b-button>
                </div>
              </b-card>
            </b-tab>
            <template #tabs-end>
              <div class="text-right ml-auto vendor-detail-more-icon file_detail_tab_end">
                <b-button
                  v-if="canViewThisAction('nominee-add', 'File') && activeTab == 0 && fileDetail.hasIntendedUser && (fileDetail.intendedUsers && fileDetail.maxIntendedUser > fileDetail.intendedUsers.length)"
                  variant="success"
                  class="mobile_create_button"
                  style="height: 40px;"
                  :style="[ isMobile() ? { 'top' : '0' } : '']"
                  @click="openCreateModal('Intended User')"
                >
                  <feather-icon
                    icon="PlusIcon"
                    class="mr-50"
                    size="16"
                  />
                  <span class="align-middle d-md-block d-none">Add Intended User</span>
                  <span class="align-middle d-md-none d-block">New</span>
                </b-button>
                <b-button
                  v-else-if="canViewThisAction('nominee-add', 'File') && activeTab == 0 && fileDetail.hasIntendedUser && (fileDetail.intendedUsers && fileDetail.maxIntendedUser <= fileDetail.intendedUsers.length)"
                  v-b-tooltip.hover="'This file has reached the maximum amount of Intended User'"
                  variant="success"
                  class="mobile_create_button"
                  style="height: 40px; opacity: 0.65;"
                  :style="[ isMobile() ? { 'top' : '0' } : '']"
                  aria-readonly=""
                >
                  <feather-icon
                    icon="PlusIcon"
                    class="mr-50"
                    size="16"
                  />
                  <span class="align-middle d-md-block d-none">Add Intended User</span>
                  <span class="align-middle d-md-none d-block">New</span>
                </b-button>
                <b-button
                  v-if="canViewThisAction('nominee-add', 'File') && ((!['Urn', 'Pedestal', 'Little Buddha'].includes(fileDetail.nvsFileType) && activeTab == 1) || (['Urn', 'Pedestal', 'Little Resurrection'].includes(fileDetail.nvsFileType) && activeTab == 0)) && fileDetail.hasNextOfKin && (fileDetail.nextOfKins && fileDetail.maxNextOfKin > fileDetail.nextOfKins.length)"
                  variant="success"
                  class="mobile_create_button"
                  style="height: 40px;"
                  :style="[ isMobile() ? { 'top' : '0' } : '']"
                  @click="openCreateModal('Next Of Kin')"
                >
                  <feather-icon
                    icon="PlusIcon"
                    class="mr-50"
                    size="16"
                  />
                  <span class="align-middle d-md-block d-none">Add Next Of Kin</span>
                  <span class="align-middle d-md-none d-block">New</span>
                </b-button>
                <b-button
                  v-else-if="canViewThisAction('nominee-add', 'File') && ((!['Urn', 'Pedestal', 'Little Buddha'].includes(fileDetail.nvsFileType) && activeTab == 1) || (['Urn', 'Pedestal', 'Little Resurrection'].includes(fileDetail.nvsFileType) && activeTab == 0)) && fileDetail.hasNextOfKin && (fileDetail.nextOfKins && fileDetail.maxNextOfKin <= fileDetail.nextOfKins.length)"
                  v-b-tooltip.hover="'This file has reached the maximum amount of Next Of Kin'"
                  variant="success"
                  class="mobile_create_button"
                  style="height: 40px; opacity: 0.65;"
                  :style="[ isMobile() ? { 'top' : '0' } : '']"
                  aria-readonly=""
                >
                  <feather-icon
                    icon="PlusIcon"
                    class="mr-50"
                    size="16"
                  />
                  <span class="align-middle d-md-block d-none">Add Next Of Kin</span>
                  <span class="align-middle d-md-none d-block">New</span>
                </b-button>
                <b-button
                  v-if="canViewThisAction('nominee-add', 'File') && ((!['Urn', 'Pedestal', 'Little Buddha'].includes(fileDetail.nvsFileType) && activeTab == 2) || (['Urn', 'Pedestal', 'Little Resurrection'].includes(fileDetail.nvsFileType) && activeTab == 1)) && (fileDetail.addressees && fileDetail.maxAddressee > fileDetail.addressees.length)"
                  variant="success"
                  class="mobile_create_button"
                  style="height: 40px;"
                  :style="[ isMobile() ? { 'top' : '0' } : '']"
                  @click="openCreateModal('Addressee')"
                >
                  <feather-icon
                    icon="PlusIcon"
                    class="mr-50"
                    size="16"
                  />
                  <span class="align-middle d-md-block d-none">Add Addressee</span>
                  <span class="align-middle d-md-none d-block">New</span>
                </b-button>
                <b-button
                  v-else-if="canViewThisAction('nominee-add', 'File') && ((!['Urn', 'Pedestal', 'Little Buddha'].includes(fileDetail.nvsFileType) && activeTab == 2) || (['Urn', 'Pedestal', 'Little Resurrection'].includes(fileDetail.nvsFileType) && activeTab == 1)) && (fileDetail.addressees && fileDetail.maxAddressee <= fileDetail.addressees.length)"
                  v-b-tooltip.hover="'This file has reached the maximum amount of Addressee'"
                  variant="success"
                  class="mobile_create_button"
                  style="height: 40px; opacity: 0.65;"
                  :style="[ isMobile() ? { 'top' : '0' } : '']"
                  aria-readonly=""
                >
                  <feather-icon
                    icon="PlusIcon"
                    class="mr-50"
                    size="16"
                  />
                  <span class="align-middle d-md-block d-none">Add Addressee</span>
                  <span class="align-middle d-md-none d-block">New</span>
                </b-button>
              </div>
            </template>
          </b-tabs>
        </b-col>
      </b-row>
    </b-card>

    <b-modal
      id="modal-add-intended-user"
      ref="add_intended_user_modal"
      modal-class="status-update-modal iu-modal"
      footer-class="iu-edit-modal-footer"
      cancel-variant="flat-primary"
      ok-variant="primary"
      ok-title="Confirm"
      cancel-title="Cancel"
      centered
      no-close-on-backdrop
      :title="addModalTitle"
      size="sm"
      @ok="intendedUserCreate"
    >
      <b-form @submit.prevent="intendedUserCreate">
        <validation-observer
          ref="intendedUserCreateForm"
        >
          <b-form-group
            label="Name*"
            label-for="h-iu-iuName"
          >
            <validation-provider
              #default="{ errors }"
              name="Name"
              vid="iuName"
              rules="required"
            >
              <b-form-input
                id="h-iu-iuName"
                v-model="iuName"
                placeholder="Name"
                name="iuName"
                class="text-uppercase"
                :state="errors.length > 0 ? false : null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <b-form-group
            label="NRIC*"
            label-for="h-iu-iuNRIC"
          >
            <validation-provider
              #default="{ errors }"
              name="NRIC"
              vid="iuNRIC"
              rules="required"
            >
              <b-form-input
                id="h-iu-iuNRIC"
                v-model="iuNRIC"
                placeholder="NRIC"
                name="iuNRIC"
                class="text-uppercase"
                :state="errors.length > 0 ? false : null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <validation-provider
            #default="{ errors }"
            name="Date of Birth"
            vid="iuDOB"
            rules="required"
          >
            <b-form-group
              label="Date of Birth*"
              label-for="iuDOB"
              :class="{ 'is-invalid': errors.length }"
            >
              <b-input-group>
                <flat-pickr
                  id="iuDOB"
                  v-model="iuDOB"
                  class="form-control flat-pickr-group"
                  placeholder="Select a Date"
                  :config="flatPickrConfigWithoutTime"
                />
                <b-input-group-append is-text>
                  <feather-icon
                    icon="CalendarIcon"
                    class="cursor-pointer"
                    data-toggle
                    size="18"
                  />
                </b-input-group-append>
              </b-input-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>
          <validation-provider
            #default="{ errors }"
            name="Relationship"
            vid="iuRelationship"
            rules="required"
          >
            <b-form-group
              label="Relationship*"
              label-for="h-iu-iuRelationship"
              :state="(errors.length > 0) ? false : null"
            >
              <v-select
                id="h-iu-iuRelationship"
                v-model="iuRelationship"
                :options="relationshipOptions"
                :clearable="false"
                class="text-uppercase"
                placeholder="Select an option"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>
          <b-form-group
            label="Mobile No.*"
            label-for="h-iu-iuContact"
            rules="required"
          >
            <validation-provider
              name="Mobile No."
              rules="required"
            >
              <!-- <cleave
                id="h-iu-iuContact"
                v-model="iuContact"
                class="form-control"
                :raw="false"
                rules="required"
                :options="options.prefix"
                :required="!iuContact"
                @input="iuContactValidation = false"
              /> -->
              <b-form-input
                id="h-iu-iuContact"
                v-model="iuContact"
                placeholder="+65 Enter 8-digits number"
                name="iuContact"
                @input="iuContactValidation = false"
              />
              <small
                v-if="iuContactValidation"
                class="text-danger display-block"
              >
                {{ iuContactError }}
              </small>
            </validation-provider>
          </b-form-group>
          <b-form-group
            label="Residential Address*"
            label-for="h-iu-iuAdd1"
            rules="required"
          >
            <validation-provider
              #default="{ errors }"
              name="Address Line 1"
              rules="required"
            >
              <b-form-input
                id="h-iu-iuAdd1"
                v-model="iuAdd1"
                placeholder="Address Line 1"
                name="iuAdd1"
                rules="required"
                class="text-uppercase"
                :state="errors.length > 0 ? false : null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <b-form-group
            label-for="h-iu-iuAdd2"
          >
            <validation-provider
              #default="{ errors }"
              name="Address Line 2"
              rules="required"
            >
              <b-form-input
                id="h-iu-iuAdd2"
                v-model="iuAdd2"
                placeholder="Address Line 2"
                name="iuAdd2"
                rules="required"
                class="text-uppercase"
                :state="errors.length > 0 ? false : null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <validation-provider
            #default="{ errors }"
            name="Select a country"
            rules="required"
          >
            <b-form-group
              label-for="h-iu-iuCountry"
              :state="(errors.length > 0) ? false : null"
            >
              <v-select
                id="h-iu-iuCountry"
                v-model="iuCountry"
                rules="required"
                :options="countryOptions"
                :clearable="false"
                class="text-uppercase"
                placeholder="Select a country"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>
          <b-form-group
            label-for="h-iu-iuPostCode"
          >
            <validation-provider
              #default="{ errors }"
              name="Post Code"
              rules="required"
            >
              <b-form-input
                id="h-iu-iuPostCode"
                v-model="iuPostCode"
                placeholder="Post Code"
                name="iuPostCode"
                rules="required"
                class="text-uppercase"
                :state="errors.length > 0 ? false : null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </validation-observer>
      </b-form>
      <template #modal-footer="{ ok, cancel }">
        <b-button
          variant="outline-primary"
          @click="cancel()"
        >
          Cancel
        </b-button>
        <b-button
          variant="primary"
          @click="ok()"
        >
          <span class="align-middle">Confirm</span>
        </b-button>
      </template>
    </b-modal>

    <b-modal
      id="modal-update-intended-user"
      ref="update_intended_user_modal"
      modal-class="status-update-modal iu-modal"
      footer-class="iu-edit-modal-footer"
      cancel-variant="flat-primary"
      ok-variant="primary"
      ok-title="Save Changes"
      cancel-title="Cancel"
      centered
      no-close-on-backdrop
      :title="editModalTitle"
      size="sm"
      @ok="intendedUserSubmitForm"
    >
      <b-form @submit.prevent="intendedUserSubmitForm">
        <validation-observer
          ref="intendedUserEditForm"
        >
          <b-form-group
            label="Name"
            label-for="h-iu-iuName"
            description="These fields can be updated in the entity’s record."
          >
            <b-form-input
              id="h-iu-iuName"
              v-model="iuName"
              placeholder="Name"
              name="iuName"
              disabled
              class="text-uppercase"
            />
          </b-form-group>
          <b-form-group
            label="NRIC"
            label-for="h-iu-iuNRIC"
          >
            <b-form-input
              id="h-iu-iuNRIC"
              v-model="iuNRIC"
              placeholder="NRIC"
              name="iuNRIC"
              class="text-uppercase"
              disabled
            />
          </b-form-group>
          <b-form-group
            label="Date of Birth"
            label-for="h-iu-iuDOB"
          >
            <b-form-input
              id="h-iu-iuDOB"
              v-model="iuDOB"
              placeholder="Date of Birth"
              name="iuDOB"
              disabled
            />
          </b-form-group>
          <validation-provider
            #default="{ errors }"
            name="Relationship"
            vid="iuRelationship"
            rules="required"
          >
            <b-form-group
              label="Relationship*"
              label-for="h-iu-iuRelationship"
            >
              <v-select
                id="h-iu-iuRelationship"
                v-model="iuRelationship"
                :options="relationshipOptions"
                :clearable="false"
                class="text-uppercase"
                placeholder="Select an option"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>
          <b-form-group
            label="Mobile No.*"
            label-for="h-iu-iuContact"
            rules="required"
          >
            <validation-provider
              name="Mobile No."
              rules="required"
            >
              <!-- <cleave
                id="h-iu-iuContact"
                v-model="iuContact"
                class="form-control"
                :raw="false"
                :options="options.prefix"
                @input="iuContactValidation = false"
              /> -->
              <b-form-input
                id="h-iu-iuContact"
                v-model="iuContact"
                placeholder="+65 Enter 8-digits number"
                name="iuContact"
                @input="iuContactValidation = false"
              />
              <small
                v-if="iuContactValidation"
                class="text-danger display-block"
              >
                {{ iuContactError }}
              </small>
            </validation-provider>
          </b-form-group>
          <b-form-group
            label="Residential Address*"
            label-for="h-iu-iuAdd1"
            rules="required"
          >
            <validation-provider
              #default="{ errors }"
              name="Address Line 1"
              rules="required"
            >
              <b-form-input
                id="h-iu-iuAdd1"
                v-model="iuAdd1"
                placeholder="Address Line 1"
                name="iuAdd1"
                rules="required"
                class="text-uppercase"
                :state="errors.length > 0 ? false : null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <b-form-group
            label-for="h-iu-iuAdd2"
          >
            <validation-provider
              #default="{ errors }"
              name="Address Line 2"
              rules="required"
            >
              <b-form-input
                id="h-iu-iuAdd2"
                v-model="iuAdd2"
                placeholder="Address Line 2"
                name="iuAdd2"
                rules="required"
                class="text-uppercase"
                :state="errors.length > 0 ? false : null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <validation-provider
            #default="{ errors }"
            name="Select a country"
            rules="required"
          >
            <b-form-group
              label-for="h-iu-iuCountry"
              :state="(errors.length > 0) ? false : null"
            >
              <v-select
                id="h-iu-iuCountry"
                v-model="iuCountry"
                rules="required"
                :options="countryOptions"
                :clearable="false"
                class="text-uppercase"
                placeholder="Select a country"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>
          <b-form-group
            label-for="h-iu-iuPostCode"
          >
            <validation-provider
              #default="{ errors }"
              name="Post Code"
              rules="required"
            >
              <b-form-input
                id="h-iu-iuPostCode"
                v-model="iuPostCode"
                placeholder="Post Code"
                name="iuPostCode"
                rules="required"
                class="text-uppercase"
                :state="errors.length > 0 ? false : null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </validation-observer>
      </b-form>
      <template #modal-footer="{ ok, cancel }">
        <b-button
          variant="outline-primary"
          @click="cancel()"
        >
          Cancel
        </b-button>
        <b-button
          variant="primary"
          @click="ok()"
        >
          <span class="align-middle">Save Changes</span>
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BCard, BRow, BButton, BCol, BImg, BForm, BFormGroup, BCardTitle, BCardHeader, BCardBody, BBadge,
  BFormInput, VBTooltip, VBToggle, BTabs, BTab, BDropdown, BDropdownItem, BInputGroup, BInputGroupAppend,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
// import Cleave from 'vue-cleave-component'
// eslint-disable-next-line import/no-extraneous-dependencies
import 'cleave.js/dist/addons/cleave-phone.us'
import vSelect from 'vue-select'
import store from '@/store/index'
import { required } from '@validations'
import '@core/scss/vue/libs/vue-flatpicker.scss'
import flatPickr from 'vue-flatpickr-component'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import moment from 'moment'
import { useUtils as useAclUtils } from '@/libs/acl/custom'

const { canViewThisAction } = useAclUtils()

export default {
  components: {
    BCard,
    BCardTitle,
    BCardHeader,
    BCardBody,
    BRow,
    BButton,
    BCol,
    BImg,
    BForm,
    BFormGroup,
    BFormInput,
    BTabs,
    BTab,
    BDropdown,
    BDropdownItem,
    BBadge,
    BInputGroup,
    BInputGroupAppend,

    vSelect,
    // Cleave,
    flatPickr,
    ValidationProvider,
    ValidationObserver,
  },
  directives: { 'b-toggle': VBToggle, 'b-tooltip': VBTooltip, Ripple },
  data() {
    return {
      canViewThisAction,
      user: store.state.auth.userData,
      fileDetail: {},
      // fileDetail: JSON.parse(localStorage.getItem('customerFileNominees')),
      fileDetailID: '',
      fileDetailActivityLogs: [],
      required,
      // eslint-disable-next-line global-require
      defaultImage: require('@/assets/images/frontend/logo-nirvana-memorial-garden.86fe2ff5.svg'),
      currentTabTitle: 'Intended User',
      activeTab: 0,
      attachments: [],
      description: '',
      attachmentKey: '',
      iuID: '',
      iuType: '',
      formattedIUType: '',
      iuIndex: '',
      iuTypeOnly: '',
      iuName: '',
      iuNRIC: '',
      iuDOB: '',
      iuRelationship: '',
      iuContact: '+65 ',
      iuAdd1: '',
      iuAdd2: '',
      iuAdd3: '',
      iuCountry: '',
      iuPostCode: '',
      iuIndexID: '',
      relationshipOptions: [
        'Brother', 'Brother in-law', 'Cousin', 'Daughter', 'Daughter in-law', 'Elder Brother', 'Father', 'Father in-law',
        'Friend', 'God-father', 'Grand Daughter', 'Grandfather', 'Grandmother', 'Grandson', 'Great Grand Father',
        'Great Grand Mother', 'Husband', 'Mother', 'Mother in-law', 'N/A', 'Nephew', 'Niece', 'Relative', 'Self', 'Sister',
        'Sister in-law', 'Son', 'Son in-law', 'Uncle', 'Wife',
      ],
      flatPickrConfigWithoutTime: {
        wrap: true, enableTime: false, dateFormat: 'd/m/Y', disableMobile: true, static: true,
      },
      countryOptions: ['SINGAPORE', 'MALAYSIA', 'OTHERS'],
      addModalTitle: 'Add Intended User',
      editModalTitle: 'Edit Intended User',
      iuContactValidation: false,
      iuContactError: 'The Mobile No. field is required',
      options: {
        prefix: {
          numericOnly: true,
          prefix: '+65',
          blocks: [3, 8],
        },
      },
    }
  },
  mounted() {
    window.scrollTo(0, 0)
    this.fetchFileDetail()
    // document.title = `${this.fileDetail.fileNo} - ${this.fileDetail.nvsFileType} | ${process.env.VUE_APP_APP_NAME || 'Nirvana Memorial Garden'}`
    // store.commit('breadcrumb/REMOVE_BREADCRUMB')
    // store.commit('breadcrumb/REMOVE_BREADCRUMB')
    // store.commit('breadcrumb/REMOVE_BREADCRUMB')
    // const newFirstMeta = {
    //   to: '/customers/files',
    //   text: 'Files',
    // }
    // store.commit('breadcrumb/UPDATE_BREADCRUMB', newFirstMeta)
    // const newSecondMeta = {
    //   to: `/customers/files/${this.$route.params.id}/show`,
    //   text: `${this.fileDetail.fileNo} - ${this.fileDetail.nvsFileType}`,
    // }
    // store.commit('breadcrumb/UPDATE_BREADCRUMB', newSecondMeta)
    // const newThirdMeta = {
    //   active: true,
    //   text: 'View All Related Nominees',
    // }
    // store.commit('breadcrumb/UPDATE_BREADCRUMB', newThirdMeta)
  },
  methods: {
    fetchFileDetail() {
      this.$http.get(`customer/files/${this.$route.params.id}/nominees`)
        .then(response => {
          this.fileDetail = response.data.file || {}
          document.title = `File - ${this.fileDetail.fileNo} | ${process.env.VUE_APP_APP_NAME || 'Nirvana Memorial Garden'}`
          // localStorage.setItem('customerFileNominees', JSON.stringify(this.fileDetail))
          store.commit('breadcrumb/REMOVE_BREADCRUMB')
          store.commit('breadcrumb/REMOVE_BREADCRUMB')
          store.commit('breadcrumb/REMOVE_BREADCRUMB')
          const newFirstMeta = {
            to: '/customers/files',
            text: 'Files',
          }
          store.commit('breadcrumb/UPDATE_BREADCRUMB', newFirstMeta)
          const newSecondMeta = {
            to: `/customers/files/${this.$route.params.id}/show`,
            text: `${this.fileDetail.fileNo} - ${this.fileDetail.nvsFileType}`,
          }
          store.commit('breadcrumb/UPDATE_BREADCRUMB', newSecondMeta)
          const newThirdMeta = {
            active: true,
            text: 'View All Related Nominees',
          }
          store.commit('breadcrumb/UPDATE_BREADCRUMB', newThirdMeta)
        })
        .catch(error => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: error.data.message || process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },
    changeTab(index, tabName) {
      this.activeTab = index
      this.currentTabTitle = tabName
    },
    getResidentialAddress(purchaser) {
      let residentialAddress = ''
      residentialAddress += purchaser.perAddress1 ? purchaser.perAddress1.toUpperCase() : ''
      residentialAddress += purchaser.perAddress2 ? `, ${purchaser.perAddress2.toUpperCase()}` : ''
      residentialAddress += purchaser.perCountry ? `, ${purchaser.perCountry.toUpperCase()}` : ''
      residentialAddress += purchaser.perPostcode ? ` ${purchaser.perPostcode.toUpperCase()}` : ''
      return residentialAddress
    },
    openCreateModal(type) {
      if (type === 'Intended User') {
        const intendedUsers = this.fileDetail.intendedUsers.map(o => Number(o.type.split('_').pop()))
        this.iuIndex = intendedUsers.length ? (Math.max(...intendedUsers) + 1) : 1
      } else if (type === 'Next Of Kin') {
        const intendedUsers = this.fileDetail.nextOfKins.map(o => Number(o.type.split('_').pop()))
        this.iuIndex = intendedUsers.length ? (Math.max(...intendedUsers) + 1) : 1
      } else if (type === 'Addressee') {
        const intendedUsers = this.fileDetail.addressees.map(o => Number(o.type.split('_').pop()))
        this.iuIndex = intendedUsers.length ? (Math.max(...intendedUsers) + 1) : 1
      }
      this.iuName = ''
      this.iuNRIC = ''
      this.iuDOB = moment().format('DD/MM/YYYY')
      this.iuRelationship = ''
      this.iuContact = '+65 '
      this.iuAdd1 = ''
      this.iuAdd2 = ''
      this.iuAdd3 = ''
      this.iuPostCode = ''
      this.iuCountry = ''
      this.iuTypeOnly = type
      this.addModalTitle = `Add ${type}`
      this.$refs.add_intended_user_modal.show()
    },
    async intendedUserEdit(user, index) {
      this.iuIndexID = user._id
      this.iuIndex = index
      this.iuType = user.type
      this.iuID = user.entityID
      let type = ''
      const number = user.type.split('_').pop()
      this.iuNRIC = user.encryptedNric
      if (user.type.includes('INTENDED_USER')) {
        type = 'Intended User'
      } else if (user.type.includes('NEXT OF KIN')) {
        type = 'Next Of Kin'
        // this.iuNRIC = user.nric
      } else {
        type = 'Addressee'
      }
      this.iuTypeOnly = type
      this.formattedIUType = `${type} ${number}`
      this.$refs.update_intended_user_modal.show()
      await this.$nextTick()
      this.iuName = user.name
      this.iuDOB = user.dob ? moment(user.dob).format('DD/MM/YYYY') : moment().format('DD/MM/YYYY')
      this.iuRelationship = user.relation
      this.iuContact = user.contact || '+65 '
      // this.iuContact = '+6512345678'
      this.iuAdd1 = user.perAddress1 ? user.perAddress1 : ''
      this.iuAdd2 = user.perAddress2 ? user.perAddress2 : ''
      this.iuAdd3 = user.perAddress3 ? user.perAddress3 : ''
      this.iuPostCode = user.perPostcode ? user.perPostcode : ''
      this.iuCountry = user.perCountry ? user.perCountry : ''
      this.editModalTitle = `Edit ${type} #${number}`
    },
    intendedUserRemove(user, index) {
      let type = ''
      let iyType = ''
      if (user.type.includes('INTENDED_USER')) {
        type = 'an intended user'
        iyType = 'Intended User'
      } else if (user.type.includes('NEXT OF KIN')) {
        type = 'a next of kin'
        iyType = 'Next Of Kin'
      } else {
        type = 'an addressee'
        iyType = 'Addressee'
      }
      this.$swal({
        title: `Remove ${user.name}`,
        html: `${user.name} will no longer be registered as ${type} for this file. `,
        // eslint-disable-next-line global-require
        imageUrl: require('@/assets/images/icons/delete.png'),
        imageWidth: 80,
        imageHeight: 80,
        imageAlt: 'Custom Icon',
        reverseButtons: true,
        showCancelButton: true,
        confirmButtonText: 'Yes, Remove',
        cancelButtonText: 'Cancel',
        customClass: {
          confirmButton: 'btn btn-primary ml-1',
          cancelButton: 'btn btn-outline-primary',
        },
        buttonsStyling: false,
      })
        .then(result => {
          if (result.value) {
            const formData = new FormData()
            let parentEntity = ''
            if (this.fileDetail && this.fileDetail.purchasers && this.fileDetail.purchasers.length) {
              parentEntity = this.fileDetail.purchasers[0].entityID
            }
            formData.append('parentEntity', parentEntity)
            formData.append('fileID', this.fileDetail.fileID)
            formData.append('iuIndexID', user._id)
            formData.append('nvsFileID', this.fileDetail._id)
            formData.append('entityID', user.entityID)
            formData.append('type', user.type)

            this.$http.post(`customer/files/${this.$route.params.id}/delete/intended-user`, formData, {
              headers: { 'Content-Type': 'multipart/form-data' },
            })
              .then(response => {
                if (response.data.success) {
                  if (iyType === 'Intended User') {
                    this.fileDetail.intendedUsers.splice(index, 1)
                  } else if (iyType === 'Next Of Kin') {
                    this.fileDetail.nextOfKins.splice(index, 1)
                  } else {
                    this.fileDetail.addressees.splice(index, 1)
                  }
                  this.$swal({
                    title: 'Nominee Deleted!',
                    html: `<strong>${user.name}</strong> has been removed`,
                    // eslint-disable-next-line global-require
                    imageUrl: require('@/assets/images/icons/save.png'),
                    imageWidth: 80,
                    imageHeight: 80,
                    imageAlt: 'Custom Icon',
                    showCancelButton: false,
                    confirmButtonText: 'Okay',
                    customClass: {
                      confirmButton: 'btn btn-primary',
                    },
                    buttonsStyling: false,
                  })
                } else {
                  this.$toast({
                    component: ToastificationContent,
                    props: {
                      title: response.data.message || process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
                      icon: 'AlertTriangleIcon',
                      variant: 'danger',
                    },
                  })
                }
              })
              .catch(error => {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: error.data.message || process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
                    icon: 'AlertTriangleIcon',
                    variant: 'danger',
                  },
                })
              })
          }
        })
    },
    intendedUserCreate(bvModalEvt) {
      bvModalEvt.preventDefault()
      this.$refs.intendedUserCreateForm.validate().then(success => {
        const validContact = true
        // if (this.iuContact.length === 12) {
        //   this.iuContactValidation = false
        //   validContact = true
        // } else {
        //   this.iuContactValidation = true
        // }
        if (success && validContact) {
          this.$swal({
            title: `Save ${this.iuTypeOnly}?`,
            html: '',
            // eslint-disable-next-line global-require
            imageUrl: require('@/assets/images/icons/fly.png'),
            imageWidth: 80,
            imageHeight: 80,
            imageAlt: 'Custom Icon',
            reverseButtons: true,
            showCancelButton: true,
            confirmButtonText: 'Yes, Save',
            cancelButtonText: 'No, Go Back',
            customClass: {
              confirmButton: 'btn btn-primary ml-1',
              cancelButton: 'btn btn-outline-primary',
            },
            buttonsStyling: false,
          })
            .then(result => {
              if (result.value) {
                const formData = new FormData()
                let parentEntity = ''
                if (this.fileDetail && this.fileDetail.purchasers && this.fileDetail.purchasers.length) {
                  parentEntity = this.fileDetail.purchasers[0].entityID
                }
                let day = '0'
                let month = '0'
                let year = '0000'
                if (this.iuDOB) {
                  day = moment(this.iuDOB, 'DD/MM/YYYY').format('D')
                  month = moment(this.iuDOB, 'DD/MM/YYYY').format('M')
                  year = moment(this.iuDOB, 'DD/MM/YYYY').format('YYYY')
                }
                formData.append('parentEntity', parentEntity)
                formData.append('fileID', this.fileDetail.fileID)
                formData.append('name', this.iuName)
                formData.append('nric', this.iuNRIC)
                formData.append('dob', moment(this.iuDOB, 'DD/MM/YYYY').format('YYYY-MM-DD'))
                formData.append('relation', this.iuRelationship)
                formData.append('phone', this.iuContact)
                formData.append('add1', this.iuAdd1)
                formData.append('add2', this.iuAdd2)
                formData.append('add3', this.iuAdd3)
                formData.append('postCode', this.iuPostCode)
                formData.append('country', this.iuCountry)
                formData.append('number', this.iuIndex)
                formData.append('type', this.iuTypeOnly)
                formData.append('day', day)
                formData.append('month', month)
                formData.append('year', year)
                formData.append('nvsFileID', this.fileDetail._id)

                this.$http.post(`customer/files/${this.$route.params.id}/add/intended-user`, formData, {
                  headers: { 'Content-Type': 'multipart/form-data' },
                })
                  .then(response => {
                    if (response.data.success) {
                      this.$set(this.fileDetail, 'intendedUsers', response.data.fileDetail.intendedUsers)
                      this.$set(this.fileDetail, 'nextOfKins', response.data.fileDetail.nextOfKins)
                      this.$set(this.fileDetail, 'addressees', response.data.fileDetail.addressees)
                      this.$refs.add_intended_user_modal.hide()
                      this.$swal({
                        title: 'Nominee Added!',
                        html: `<strong>${this.iuTypeOnly}</strong> has been added`,
                        // eslint-disable-next-line global-require
                        imageUrl: require('@/assets/images/icons/save.png'),
                        imageWidth: 80,
                        imageHeight: 80,
                        imageAlt: 'Custom Icon',
                        showCancelButton: false,
                        confirmButtonText: 'Okay',
                        customClass: {
                          confirmButton: 'btn btn-primary',
                        },
                        buttonsStyling: false,
                      })
                    } else {
                      this.$toast({
                        component: ToastificationContent,
                        props: {
                          title: response.data.message || process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
                          icon: 'AlertTriangleIcon',
                          variant: 'danger',
                        },
                      })
                    }
                  })
                  .catch(error => {
                    this.$toast({
                      component: ToastificationContent,
                      props: {
                        title: error.data.message || process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
                        icon: 'AlertTriangleIcon',
                        variant: 'danger',
                      },
                    })
                  })
              }
            })
        }
      })
    },
    intendedUserSubmitForm(bvModalEvt) {
      bvModalEvt.preventDefault()
      this.$refs.intendedUserEditForm.validate().then(success => {
        const validContact = true
        // if (this.iuContact.length === 12) {
        //   this.iuContactValidation = false
        //   validContact = true
        // } else {
        //   this.iuContactValidation = true
        // }
        if (success && validContact) {
          this.$swal({
            title: 'Save current changes?',
            html: 'Your changes will overwrite the current information, are you sure?',
            // eslint-disable-next-line global-require
            imageUrl: require('@/assets/images/icons/warning.png'),
            imageWidth: 80,
            imageHeight: 80,
            imageAlt: 'Custom Icon',
            reverseButtons: true,
            showCancelButton: true,
            confirmButtonText: 'Yes, Save Changes',
            cancelButtonText: 'No, Go Back',
            customClass: {
              confirmButton: 'btn btn-primary ml-1',
              cancelButton: 'btn btn-outline-primary',
            },
            buttonsStyling: false,
          })
            .then(result => {
              if (result.value) {
                const formData = new FormData()
                let parentEntity = ''
                if (this.fileDetail && this.fileDetail.purchasers && this.fileDetail.purchasers.length) {
                  parentEntity = this.fileDetail.purchasers[0].entityID
                }
                let day = '0'
                let month = '0'
                let year = '0000'
                if (this.iuDOB) {
                  day = moment(this.iuDOB, 'DD/MM/YYYY').format('D')
                  month = moment(this.iuDOB, 'DD/MM/YYYY').format('M')
                  year = moment(this.iuDOB, 'DD/MM/YYYY').format('YYYY')
                }
                formData.append('parentEntity', parentEntity)
                formData.append('fileID', this.fileDetail.fileID)
                formData.append('entityID', this.iuID)
                formData.append('type', this.iuType)
                formData.append('name', this.iuName)
                formData.append('nric', this.iuNRIC)
                formData.append('dob', moment(this.iuDOB, 'DD/MM/YYYY').format('YYYY-MM-DD'))
                formData.append('relation', this.iuRelationship)
                formData.append('phone', this.iuContact)
                formData.append('add1', this.iuAdd1)
                formData.append('add2', this.iuAdd2)
                formData.append('add3', this.iuAdd3)
                formData.append('postCode', this.iuPostCode)
                formData.append('country', this.iuCountry)
                formData.append('day', day)
                formData.append('month', month)
                formData.append('year', year)
                formData.append('iuIndexID', this.iuIndexID)
                formData.append('nvsFileID', this.fileDetail._id)

                this.$http.post(`customer/files/${this.$route.params.id}/edit/intended-user`, formData, {
                  headers: { 'Content-Type': 'multipart/form-data' },
                })
                  .then(response => {
                    if (response.data.success) {
                      this.$set(this.fileDetail, 'intendedUsers', response.data.fileDetail.intendedUsers)
                      this.$set(this.fileDetail, 'nextOfKins', response.data.fileDetail.nextOfKins)
                      this.$set(this.fileDetail, 'addressees', response.data.fileDetail.addressees)
                      this.$refs.update_intended_user_modal.hide()
                      this.$swal({
                        title: 'Nominee Updated!',
                        html: `<strong>${this.formattedIUType}</strong> has been updated`,
                        // eslint-disable-next-line global-require
                        imageUrl: require('@/assets/images/icons/save.png'),
                        imageWidth: 80,
                        imageHeight: 80,
                        imageAlt: 'Custom Icon',
                        showCancelButton: false,
                        confirmButtonText: 'Okay',
                        customClass: {
                          confirmButton: 'btn btn-primary',
                        },
                        buttonsStyling: false,
                      })
                    } else {
                      this.$toast({
                        component: ToastificationContent,
                        props: {
                          title: response.data.message || process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
                          icon: 'AlertTriangleIcon',
                          variant: 'danger',
                        },
                      })
                    }
                  })
                  .catch(error => {
                    this.$toast({
                      component: ToastificationContent,
                      props: {
                        title: error.data.message || process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
                        icon: 'AlertTriangleIcon',
                        variant: 'danger',
                      },
                    })
                  })
              }
            })
        }
      })
    },
  },
}
</script>

  <style scoped>
    .flatpickr-current-month .flatpickr-monthDropdown-months span, .flatpickr-current-month .numInputWrapper span {
        display: inline-block;
        opacity: 1;
    }
    [dir=ltr] .numInputWrapper span {
        display: inline-block;
        opacity: 1;
    }
    .timeline-variant-info .timeline-item-point {
      background-color: #00cfe8 !important;
    }
    .low-opacity {
      opacity: 0.6;
    }
    .high-opacity div {
      opacity: 1 !important;
    }
    .padding-two {
      padding: 2rem !important;
    }
    .padding-one {
      padding: 1.5rem !important;
    }
    .shadow-background {
      background: #F5F8FB;
    }
    .purple-button {
      background: #9B5899 !important;
    }
    .purple-row {
      background: rgba(155, 88, 153, 0.12);
    }
    .no-background {
      background: none !important;
    }
    .background-card {
      background: #9FB8D8;
      border-radius: 6px;
    }

    .red-trash-button {
      color: #D91B35 !important;
    }
    .shadow-body {
      background: #FFFFFF;
      box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.06) !important;
      border-radius: 6px;
      padding: 1.5rem !important;
    }

    .btn-group-toggle [class*=btn-outline-]:not(:last-child) {
      border-right-width: 1px !important;
    }

    .link-tag {
      color: #104D9D;
    }

    .autosuggest__results-container .autosuggest__results {
      background-color: #fff;
      margin-top: 1rem;
      border-radius: 0.5rem;
      -webkit-box-shadow: 0 15px 30px 0 rgba(0,0,0,.11),0 5px 15px 0 rgba(0,0,0,.08)!important;
      box-shadow: 0 15px 30px 0 rgba(0,0,0,.11),0 5px 15px 0 rgba(0,0,0,.08)!important;
    }
    .autosuggest__results-container .autosuggest__results {
      position: absolute;
      width: 100%;
      overflow-y: auto;
      max-height: 40vh;
    }
  </style>
